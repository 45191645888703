<template>
    <header class="flex align-end relative bg-accent-3" :class="['header-' + type]">
        <slot name="bg">
            <img
                class="header__bg absolute z-1 object-cover"
                :src="cdnurl(image)"
                :title="image_caption"
                fetchpriority="high"
            />
        </slot>
        <div class="w-xxl c relative z-5">
            <div class="header__card rounded shadow-m bg-accent-3 py-xl px-xl w-12 m:w-8 l:w-6 xl:w-4">
                <slot name="before" />
                <h2>{{ title }}</h2>
                <div class="color-secondary mt-m mb-l" v-html="nl2br(text)" />
                <Actions :actions="actions ? actions : (action ? [action] : '')" />
                <slot name="after" />
            </div>
        </div>
    </header>
</template>

<script>
import { strings } from 'p5-helpers';

export default {
    props: {
        type: String,
        title: String,
        text: String,
        actions: Array,
        image: String,
        image_caption: String,
    },
    methods: {
        nl2br: strings.nl2br,
    }
};
</script>

<style>
    header {
        padding-top: var(--size-xxl);
        box-shadow: inset var(--shadow-m);
    }
        header.header-large {
            min-height: 40vw;
        }
        .header__bg {
            top: 0; left: 0; width: 100%; height: 100%;
        }
    @media (max-width: 48em) {
        header.header-large {
            padding-top: 40vw;
        }
        .header__bg { height: 45vw; }
    }
    .header__card {
        margin-bottom: calc(var(--size-xl) * -1);
    }
</style>
