var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Header',{attrs:{"type":"large","title":_vm.settings.company_name,"text":_vm.formattedAddress,"actions":[
        {
            title: _vm.$t('contact.actions.mail_us'),
            class: 'bg-accent mb-r',
            href: 'mailto:' + _vm.settings.company_email,
        }
    ]},scopedSlots:_vm._u([{key:"bg",fn:function(){return [_c('img',{staticClass:"-fullwidth absolute z-1 header__bg",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.mapUrl,"alt":"Locatie"}})]},proxy:true},{key:"after",fn:function(){return [_c('div',{staticClass:"size-6 color-secondary mt-xl",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.settings['contact_text_' + _vm.lang]))}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }