<template>
    <Header
        type="large"
        :title="settings.company_name"
        :text="formattedAddress"
        :actions="[
            {
                title: $t('contact.actions.mail_us'),
                class: 'bg-accent mb-r',
                href: 'mailto:' + settings.company_email,
            }
        ]"
    >
        <template v-slot:bg>
            <img :src="mapUrl" class="-fullwidth absolute z-1 header__bg" style="object-fit:cover" alt="Locatie" />
        </template>
        <template v-slot:after>
            <div class="size-6 color-secondary mt-xl" v-html="nl2br(settings['contact_text_' + lang])" />
        </template>
    </Header>
</template>

<script>
import Header from '@/components/Header.vue';
import { strings } from 'p5-helpers';

export default {
    name: 'Contact',
    components: {
        Header,
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },

        settings() {
            const data = this.$store.getters.data;
            if (data.settings) {
                return data.settings[0];
            }
            return {};
        },

        mapUrl() {
            if (this.settings?.company_address) {
                const url = new URL('https://maps.googleapis.com/maps/api/staticmap');
                url.searchParams.set('size', '640x640');
                url.searchParams.set('scale', '2');
                url.searchParams.set('zoom', '10');
                url.searchParams.set('center', `${this.settings.company_address.coords.lat},${this.settings.company_address.coords.lng - 0.25}`);
                url.searchParams.set('markers', `${this.settings.company_address.coords.lat},${this.settings.company_address.coords.lng}`);
                url.searchParams.set('key', process.env.VUE_APP_GOOGLE_API_KEY);
                return url.toString();
            }
            return '';
        },

        formattedAddress() {
            let content = '';
            if (this.settings.company_address && this.settings.company_address.formatted) {
                content += this.settings.company_address.formatted.replace(/,/g, '<br />');
                content += '<br />';
            }
            if (this.settings.company_email) {
                content += `<br /><a href="mailto:${this.settings.company_email}">${this.settings.company_email}</a>`;
            }
            if (this.settings.phone_clim) {
                content += `<br /><a href="tel:${this.settings.phone_clim}">Clim: ${this.settings.phone_clim}</a>`;
            }
            if (this.settings.phone_leon) {
                content += `<br /><a href="tel:${this.settings.phone_leon}">Leon: ${this.settings.phone_leon}</a>`;
            }
            return content;
        },
    },
    methods: {
        nl2br: strings.nl2br,
    },
};
</script>
